<template>
    <div>
        <Panel module="Operacional" title="Movimentação em Massa" layout="large" :showFilter="false" :showSearch="false" :showButtons="false">
            <div slot="content-main">
                <br />
                <MovementBulkUpdate />
            </div>
        </Panel>
    </div>
</template>

<script>


import Panel from "@nixweb/nixloc-ui/src/component/layout/Panel";
import MovementBulkUpdate from '../../../components/modules/operational/movement-bulk/MovementBulkUpdate.vue'

import { mapGetters, mapMutations } from "vuex";

export default {
    name: "MovementBulkUpdateView",
    components: {
        Panel, MovementBulkUpdate
    },
    data() {
        return {
            id: this.$route.params.id,
        };
    },
    mounted() {
        this.removeLoading(["panel"]);
    },
    computed: {
        ...mapGetters("generic", ["event"]),
    },
    methods: {
        ...mapMutations("generic", ["removeLoading"]),

    },
};
</script>