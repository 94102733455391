<template>
  <div>
    <br />
    <div class="text-center">
      <div class="div-molded">
        <Molded>
          <div class="msg-success">Movimentação processada com Sucesso!</div>
          <div class="icon-success">
            <i class="fa-light fa-circle-check"></i>
          </div>
          <div>
            <Button _key="btnBack" type="info" title="Voltar" classIcon="fa-solid fa-arrow-left" size="small"
              :clicked="execute" />
          </div>
          <br />
        </Molded>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";

export default {
  name: "SuccessMovement",
  props: {
    clicked: Function,
  },
  components: {
    Button,
    Molded,
  },
  methods: {
    execute() {
      let self = this;
      setTimeout(function () {
        if (self.clicked) self.clicked();
      }, 200);
    }
  }
};
</script>

<style scoped>
.div-molded {
  width: 550px;
  margin: auto;
}

.msg-success {
  font-size: 18px;
}

.icon-success {
  font-size: 55px;
  color: #38a169;
  margin-top: 10px;
  margin-bottom: 15px;
}
</style>