<template>
    <tr
        :class="{ 'back-danger': item.status == 'erro' && !item.finished, 'back-success': item.status != 'erro' && !item.finished, 'back-finished': item.finished }">
        <td>
            <span> {{ item.productFullName }}</span>
            <div v-if="item.categoryProductColor" class="text-center tag-product-category"
                :style="'background-color:' + item.categoryProductColor">
                <span> {{ item.categoryProductName }}</span>
            </div>
            <div v-else class="tag-product-category-no-color text-center">
                <span> {{ item.categoryProductName }}</span>
            </div>
        </td>
        <td class="text-center">
            <div class="div-margin">
                <span> {{ item.barCode }}</span>
                <span>{{ item.barCodeProduct }}</span>
            </div>
        </td>
        <td>
            <div class="div-margin">
                <InputTextEdit v-model="item.description" :enter="update" />
            </div>
        </td>
        <td class="text-center td-quantity">
            <div class="div-margin">
                <span v-if="item.barCode">1</span>
                <span v-else>
                    <IncrementDecrement v-if="!item.finished" :min="1" v-model="item.quantity" :changed="update" />
                    <span v-else>{{ item.quantity }}</span>
                </span>
            </div>
        </td>
        <td class="td-name">
            <div class="div-margin truncate-text">
                <span v-if="item.numberRent">{{ item.numberRent }} - {{ item.customerName }}</span>
                <span v-else> {{ item.rentFullName }}</span>
                <div class="text-center" v-if="item.listProductEqual.length > 0 && !item.finished">
                    <Button :_key="item.id" :title="titleTotalRent(item.listProductEqual.length)" color="white"
                        backGroundColor="#00A2A7" classIcon="" size="small" :clicked="openConflict" />
                </div>
            </div>
            <div class="truncate-text" v-if="item.finished">
                <div class="text-center" v-if="item.listProductEqualResponse.length > 0">
                    <Button :_key="item.id" :title="titleTotalRent(item.listProductEqualResponse.length)" color="white"
                        backGroundColor="#00A2A7" classIcon="" size="small" :clicked="openConflict" />
                </div>
            </div>
        </td>
        <td class="text-center">
            <div>
                <span v-if="item.status == 'erro' && !item.finished">
                    <div>
                        <i class="fa-solid fa-do-not-enter icon-movement-danger"></i>
                    </div>
                    <span class="link" @click="openError(item.messages)">Erro(s)</span>
                </span>
                <i v-if="item.finished" class="fa-solid fa-memo-circle-check icon-movement-finished"></i>
                <i v-if="!item.finished && item.status != 'erro'"
                    class="fa-solid fa-circle-check icon-movement-success"></i>
            </div>
        </td>
        <td class="text-right">
            <div v-if="!item.finished" class="div-margin">
                <Button :_key="'btnRemove' + item.id" type="danger" classIcon="fa-solid fa-trash" size="small"
                    :clicked="remove" />
            </div>
        </td>
    </tr>
</template>

<script>

import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import IncrementDecrement from "@nixweb/nixloc-ui/src/component/forms/IncrementDecrement.vue";
import InputTextEdit from "@nixweb/nixloc-ui/src/component/forms/InputTextEdit.vue";

import { mapMutations } from "vuex";

export default {
    name: "ItemMovementBulk",
    props: ["item"],
    components: {
        Button,
        IncrementDecrement,
        InputTextEdit
    },
    methods: {
        ...mapMutations("generic", ["addEvent", "removeLoading"]),
        update() {
            this.addEvent({ name: "productBulkUpdate", data: this.item, });
        },
        remove() {
            this.addEvent({ name: "productBulkRemoved", data: this.item, });
        },
        openError(erros) {
            this.addEvent({ name: "openError", data: erros, });
        },
        openConflict() {
            this.item.listProductEqualChanged = this.item.finished ? this.item.listProductEqualResponse : this.item.listProductEqual;
            this.addEvent({ name: "openConflict", data: this.item, });
            this.removeLoading([this.item.id]);
        },
        titleTotalRent(total) {
            var title = total > 1 ? "Locações" : "Locação";
            return title;
        }
    },
}
</script>

<style scoped>
.td-quantity {
    width: 120px;
}

.div-margin {
    margin-top: 8px;
}

.icon-movement-success {
    font-size: 18px;
    color: #1D9E1D;
    margin-top: 13px;
}

.icon-movement-finished {
    font-size: 18px;
    color: #1990FF;
    margin-top: 13px;
}

.icon-movement-danger {
    font-size: 18px;
    color: red;
}

.td-name {
    width: 200px;
}

.truncate-text {
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.back-danger {
    background-color: #FFE8E8;
}

.back-success {
    background-color: #EBF8F4;
}

.back-finished {
    background-color: #E6F7FF;
}

.link {
    color: #3f529b;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}
</style>