<template>
    <div>
        <table class="table table-responsive-xs">
            <thead>
                <tr>
                    <th>
                        <span class="title-header"> Locação </span>
                    </th>
                </tr>
            </thead>
            <tbody v-for="(data, groupName) in grouped">
                <td class="group" colspan="8">
                    <div>
                        <span v-if="groupName" class="title-group">
                            {{ groupName }}
                        </span>
                        <span v-else class="title-group">
                            <span class="without-group">Sem grupo</span>
                        </span>
                    </div>
                </td>
                <tr v-for="item in data">
                    <td class="td-product td">
                        <div class="div-product">
                            <span>
                                <i class="fas fa-box"></i> {{
                                    item.productName }}
                            </span>
                            <span>
                                - {{ item.quantity }}
                            </span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";

export default {
    name: "ListItemGrouped",
    props: ["items"],
    computed: {
        ...mapGetters("generic", ["groupBy"]),
        grouped() {
            var list = this.groupBy({
                array: this.items,
                key: "rentFull",
            });
            return list;
        },
    }
}

</script>
<style scoped>
.table th,
.table td {
    height: 10px !important;
    padding-left: 5px !important;
    padding-top: 7px !important;
    padding-bottom: 5px !important;
    padding-right: 5px !important;
    padding-left: 10px !important;
    border-bottom: 0px !important;
}

.title-header {
    font-size: 14px;
    color: #757d8c;
    font-weight: 400;
    text-transform: uppercase;
}

.group {
    background-color: #fafafc;
    font-size: 14px;
    font-weight: 400;
}

.title-group {
    font-size: 14px;
}

.div-product {
    margin-left: 20px;
}
</style>