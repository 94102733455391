<template>
    <div class="div-main"
        :class="{ 'disabled': itemEqual.quantity == 0, 'back-danger': item.status == 'erro' && !item.finished, 'back-success': item.status != 'erro' && !item.finished, 'back-finished': item.finished }">
        <div class="molded">
            <b-row>
                <b-col sm="2">
                    <Badge :title="'Nº' + itemEqual.numberRent.toString()" type="primary" size="medium" />
                </b-col>
                <b-col sm="8">
                    <span class="title"> {{ itemEqual.customerName }}</span>
                </b-col>
                <b-col sm="2">
                    <div v-if="!item.finished">
                        <IncrementDecrement :min="0" :max="itemEqual.maxQuantity" v-model="itemEqual.quantity"
                            :changed="update" />
                    </div>
                    <div v-else>
                        <span>
                            {{ itemEqual.quantity }}
                        </span>
                    </div>

                </b-col>
            </b-row>
            <div>
                <small> {{ itemEqual.identification }}</small>
            </div>
            <div>
                <small>Saída {{ itemEqual.deliveryDateHour | moment("DD/MM/YYYY HH:MM") }}</small>
                <small> - Início {{ itemEqual.deliveryDateHour | moment("DD/MM/YYYY HH:MM") }}</small>
                <small> - Fim {{ itemEqual.deliveryDateHour | moment("DD/MM/YYYY HH:MM") }}</small>
                <small> - Retorno {{ itemEqual.deliveryDateHour | moment("DD/MM/YYYY HH:MM") }}</small>
            </div>
        </div>
    </div>
</template>


<script>

import Badge from "@nixweb/nixloc-ui/src/component/layout/Badge.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import IncrementDecrement from "@nixweb/nixloc-ui/src/component/forms/IncrementDecrement.vue";

import { mapMutations } from "vuex";

export default {
    name: "ItemConflict",
    props: ["item", "itemEqual"],
    components: {
        Button,
        Molded,
        Badge,
        IncrementDecrement,
    },
    methods: {
        ...mapMutations("generic", ["addEvent"]),
        update() {
            this.addEvent({ name: "productBulkUpdate", data: this.item, });
        },
    }
}

</script>
<style scoped>
.div-main {
    margin-bottom: 10px;
}

.molded {
    border: 1px solid #e8eaed;
    border-radius: 10px;
    padding: 10px;
    padding-top: 15px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 10px;
}

.disabled {
    opacity: 0.5;
}

.back-danger {
    background-color: #FFE8E8;
}

.back-success {
    background-color: #EBF8F4;
}

.back-finished {
    background-color: #E6F7FF;
}
</style>