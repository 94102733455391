<template>
    <div>
        <SuccessMovement v-if="success" :clicked="back" />
        <div v-if="loadingGenerate">
            <Loading type="line" :center="true" />
            <div class="title">Aguarde, estamos preparando a
                <span v-if="data.movementBulk.type == 1">saída...</span>
                <span v-else>retorno...</span>
            </div>
        </div>

        <div v-if="!loadingGenerate && !success">
            <Molded>
                <b-row>
                    <b-col xs="12">
                        <div>
                            <span class="title title-main">Tipo </span>
                            <span class="title">
                                <span v-if="data.movementBulk.type == 1">
                                    <i class="fa-solid fa-circle-arrow-right icon-output"></i>
                                    Saída
                                </span>
                                <span v-else>
                                    <i class="fa-solid fa-circle-arrow-left icon-return"></i>
                                    Retorno
                                </span>
                            </span>
                        </div>
                        <div>
                            <span class="title title-main">Identificação </span>
                            <span class="title">{{ data.movementBulk.name }}</span>
                        </div>
                        <div v-if="data.movementBulk.type == 1 && data.numberRent > 0">
                            <span class="title title-main">Locação </span>
                            <span class="title">
                                {{ data.numberRent }}
                            </span>
                        </div>
                        <div v-if="data.movementBulk.type == 1 && data.customer">
                            <span class="title title-main">Cliente </span>
                            <span class="title">
                                {{ data.customer.person.companyName }}
                                ({{ data.customer.person.tradeName }})
                            </span>
                        </div>
                        <div v-if="data.movementBulk.type == 1 && data.movementBulk.deliveryDateHour">
                            <span class="title title-main">Período </span>
                            <span class="title">
                                Saída {{ data.movementBulk.deliveryDateHour | moment("DD/MM/YYYY HH:mm") }}</span>
                            <span class="title"> - </span>
                            <span class="title">
                                Retorno {{ data.movementBulk.devolutionDateHour | moment("DD/MM/YYYY HH:mm") }}</span>
                        </div>
                        <div v-if="data.movementBulk.rentsLinked">
                            <span class="title title-main">Locação </span>
                            {{ data.movementBulk.rentsLinked }}
                        </div>
                        <Button v-if="!data.finished" _key="btnEdit" type="edit" title="Editar"
                            classIcon="fa-solid fa-pen-to-square" size="small" :clicked="edit" />
                    </b-col>
                </b-row>
            </Molded>
            <div class="div-alert" v-if="data.finished">
                <Alert type="info">
                    <span>
                        Atenção, movimentação <b>FINALIZADA</b>, não é permitido alterar!
                    </span>
                </Alert>
            </div>
            <div v-if="!data.finished">
                <br>
                <Molded>
                    <SearchByBarcode urlGet="/api/v1/moviment/generate-moviment/get-by-barcode"
                        :params="{ statusPatrimony }" />
                    <div class="div-search side-by-side">
                        <Button _key="btnAddSearch" type="primary" title="Pesquisar Manual"
                            classIcon="fa-regular fa-magnifying-glass" size="small" :clicked="executeSearch" />
                    </div>

                    <div class="div-search side-by-side">
                        <Button _key="btnAddSearch" color="white" backGroundColor="#156EBE" title="Leitura RFID"
                            classIcon="fa-solid fa-tower-broadcast" size="small" :clicked="executeSearch" />
                    </div>
                </Molded>
            </div>
            <br>

            <Loading v-if="loading" type="line" :center="false" />
            <b-tabs>
                <b-tab title="Principal">
                    <b-row>
                        <b-col sm="6">
                            <input type="text" v-model="search" placeholder="Pesquisar..." />
                        </b-col>
                        <b-col sm="6">
                            <div class="text-right">
                                <div class="container-icon icon-refresh side-by-side">
                                    <i v-if="!loading" class="fa-sharp fa-solid fa-arrows-rotate icon-filter-dash title"
                                        @click="getAll"></i>
                                    <i v-else
                                        class="fa-sharp fa-solid fa-arrows-rotate loading icon-filter-dash title"></i>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    <table class="table table-responsive-xs">
                        <thead>
                            <tr>
                                <th>
                                    <span class="title-header">Produto</span>
                                </th>
                                <th class="text-center">
                                    <span class="title-header">Código</span>
                                </th>
                                <th>
                                    <span class="title-header">Descrição</span>
                                </th>
                                <th class="text-center">
                                    <span class="title-header">Qtd</span>
                                </th>
                                <th class="text-center">
                                    <span v-if="data.movementBulk.type == 2" class="title-header">Nº Locação</span>
                                </th>
                                <th class="text-center">
                                    <span class="title-header">Status</span>
                                </th>
                                <th>

                                </th>
                            </tr>
                        </thead>
                        <tbody v-for="item in sortedList" :key="item.id">
                            <ItemMovementBulk :item="item" />
                        </tbody>
                        <tbody v-show="items.length == 0">
                            <tr>
                                <td colspan="12">
                                    <span>Nenhum produto encontrado!</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </b-tab>
                <b-tab v-if="data.movementBulk.type == 2 && data.finished" title="Resumo">
                    <b-row>
                        <b-col sm="6">
                            BOTÃO IMPRIMIR
                        </b-col>
                        <b-col sm="6">
                            <div class="text-right">
                                <div class="container-icon icon-refresh side-by-side">
                                    <i v-if="!loading" class="fa-sharp fa-solid fa-arrows-rotate icon-filter-dash title"
                                        @click="getAll"></i>
                                    <i v-else
                                        class="fa-sharp fa-solid fa-arrows-rotate loading icon-filter-dash title"></i>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    <ListItemGrouped :items="itemsGrouped" />
                </b-tab>
            </b-tabs>
            <br>
            <div v-if="!data.finished">
                <div class="text-center" v-if="data.movementBulk.type == 1">
                    <Button _key="btnGenerateOutput" title="Gerar Saída" :disabled="!isValid" type="success"
                        classIcon="fa-solid fa-circle-check" size="large" :clicked="generateOutput" />
                </div>
                <div class="text-center" v-else>
                    <Button _key="btnGenerateReturn" title="Gerar Retorno" :disabled="!isValid" type="success"
                        classIcon="fa-solid fa-circle-check" size="large" :clicked="generateReturn" />
                </div>
            </div>
        </div>

        <Modal title="Pesquisar" :width="1200" :height="750" v-if="showModal('searchProduct')">
            <SearchProduct :types="'0,1,2,0,0'" :status="1" :statusPatrimony="statusPatrimony" :showPrice="false"
                :onlyPatrimony="true" :showAvailability="false" :addProduct="addProduct">
            </SearchProduct>
        </Modal>

        <Modal title="Erro(s)" :width="550" :height="750" v-if="showModal('error')">
            <div v-for="message in messagesError">
                <Alert type="danger">
                    <span> {{ message }}</span>
                </Alert>
            </div>
        </Modal>

        <Modal title="Locações" :width="800" :height="750" v-if="showModal('conflict')">
            <ScrollBar :minHeight="400" :maxHeight="600">
                <div v-for="item in items">
                    <div v-for="itemEqual in item.listProductEqualChanged">
                        <ItemConflict :item="item" :itemEqual="itemEqual" />
                    </div>
                </div>
            </ScrollBar>
        </Modal>

        <SideBar title="Movimentação">
            <div v-if="sideBar.open">
                <MovementBulkCreateUpdate :data="data" />
            </div>
        </SideBar>

    </div>
</template>

<script>

import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import SideBar from "@nixweb/nixloc-ui/src/component/forms/SideBar";
import ListItemGrouped from './ListItemGrouped.vue'
import SuccessMovement from './SuccessMovement.vue'

import ItemConflict from './ItemConflict.vue';
import SearchByBarcode from '../../stock/search-product/SearchByBarcode.vue';
import SearchProduct from '../../stock/search-product/SearchProduct.vue';
import ItemMovementBulk from './ItemMovementBulk.vue';
import MovementBulkCreateUpdate from './MovementBulkCreateUpdate.vue'

import { mapActions, mapMutations, mapState, mapGetters } from "vuex";

export default {
    name: "MovementBulkUpdate",
    components: {
        Molded, Button, Alert, Modal, Loading,
        SearchByBarcode, SearchProduct, ItemMovementBulk, ItemConflict, ScrollBar, SideBar, MovementBulkCreateUpdate, SuccessMovement, ListItemGrouped
    },
    data() {
        return {
            id: this.$route.params.id,
            urlGet: "/api/v1/moviment/generate-moviment/get-by-barcode",
            urlGetById: "/api/v1/operational/movement-bulk/get-by-id",
            urlGetItem: "/api/v1/operational/items-movement-bulk/get-all",
            urlGetGroupedItem: "/api/v1/operational/items-movement-bulk/get-all-grouped",
            urlCreateItem: "/api/v1/operational/items-movement-bulk/create",
            urlUpdateItem: "/api/v1/operational/items-movement-bulk/update",
            urlValidateItem: "/api/v1/operational/items-movement-bulk/validate",
            urlDeleteItem: "/api/v1/operational/items-movement-bulk/delete",
            urlGenerateOutput: "/api/v1/operational/movement-bulk/generate-output",
            urlGenerateReturn: "/api/v1/operational/movement-bulk/generate-return",
            statusPatrimony: 0,
            message: "",
            data: {},
            items: [],
            itemsGrouped: [],
            productRentFiltered: [],
            search: "",
            loading: true,
            loadingGenerate: false,
            success: false,
            messagesError: "",
        }
    },
    mounted() {
        this.getById();
        this.getAll();
        this.getAllGrouped();
    },
    computed: {
        ...mapState("generic", ["modal", "sideBar", "event"]),
        ...mapGetters("generic", ["showModal"]),
        sortedList() {
            return [...this.productRentFiltered].sort((a, b) => {
                if (a.categoryProductName < b.categoryProductName) return -1;
                if (a.categoryProductName > b.categoryProductName) return 1;

                if (a.productFullName < b.productFullName) return -1;
                if (a.productFullName > b.productFullName) return 1;

                return 0;
            });
        },
        isValid() {
            if (this.sortedList.length == 0) {
                return false;
            } else {
                return !this.sortedList.some(item => item.status === "erro");
            }
        }
    },
    methods: {
        ...mapMutations("generic", ["openModal", "openSideBar", "hideModal", "removeLoading"]),
        ...mapActions("generic", ["postApi", "putApi", "getApi", "deleteAllApi"]),
        executeSearch() {
            this.openModal("searchProduct");
            this.removeLoading(["btnAddSearch"]);
        },
        addProduct(item) {
            var obj = {
                productId: item.productId,
                patrimonyId: item.patrimonyId,
                quantity: item.quantity,
                movementBulkId: this.id,
            }
            this.create(obj);

            let self = this;
            setTimeout(function () {
                self.removeLoading([item.id]);
            }, 200);

        },
        create(obj) {
            let params = { url: this.urlCreateItem, obj: obj, notNotifyToast: true };
            this.postApi(params).then((response) => {
                if (response.success) {
                    this.items.push(response.content.content);
                }
            });
        },
        update(item) {
            let params = {
                url: this.urlUpdateItem, obj: {
                    id: item.id,
                    description: item.description,
                    quantity: item.quantity
                },
                notNotifyToast: true
            };
            this.putApi(params).then((response) => { });
        },
        async getAll() {
            this.loading = true;
            this.items = [];
            let params = {
                url: this.urlGetItem,
                obj: { movementBulkId: this.id },
            };
            const response = await this.getApi(params);
            this.items = response.content;
            this.loading = false;
            this.removeLoading(["btnRefresh"]);
        },
        async getAllGrouped() {
            this.loading = true;
            this.items = [];
            let params = {
                url: this.urlGetGroupedItem,
                obj: { movementBulkId: this.id },
            };
            const response = await this.getApi(params);
            this.itemsGrouped = response.content;
            this.loading = false;
            this.removeLoading(["btnRefresh"]);
        },
        async validate(item, isReturn) {
            item.type = this.data.type;
            let params = {
                url: this.urlValidateItem,
                obj: item,
                notNotifyToast: true
            };

            const response = await this.putApi(params);
            if (response.success) {

                if (!isReturn) {
                    this.items = this.items.map(itemValid =>
                        itemValid.id === item.id ? response.content : itemValid
                    );
                }

                if (isReturn) return response.content;
            } else {
                return null;
            }
        },
        async getAll() {
            this.loading = true;
            this.items = [];
            let params = {
                url: this.urlGetItem,
                obj: { movementBulkId: this.id },
            };
            const response = await this.getApi(params);

            for (let item of response.content) {
                var itemValid = await this.validate(item, true);
                this.items.push(itemValid);
            }

            this.loading = false;
        },
        getById() {
            let params = { url: this.urlGetById, obj: { id: this.id } };
            this.getApi(params).then((response) => {
                this.data = response.content;
                this.statusPatrimony = this.data.movementBulk.type;
            });
        },
        applyFilter() {
            const searchLower = this.search.toLowerCase();
            this.productRentFiltered = this.items.filter(item =>
                item.productFullName.toLowerCase().includes(searchLower)
            );
        },
        delete(id) {
            let params = {
                url: this.urlDeleteItem,
                selected: [id],
            };

            this.deleteAllApi(params).then((response) => {
                if (response.success) {
                    const newArray = this.items.filter(item => item.id !== id);
                    this.items = newArray;
                }
                this.removeLoading(["btnRemove"]);
            });
        },
        generateReturn() {
            this.loadingGenerate = true;
            let params = { url: this.urlGenerateReturn, obj: this.items, notNotifyToast: true };
            this.postApi(params).then((response) => {
                if (response.success) {
                    this.removeLoading(["btnGenerateReturn"]);
                    this.getById();
                    this.getAll();
                    this.loadingGenerate = false;
                    this.success = true;
                }
            });
        },
        generateOutput() {
            this.loadingGenerate = true;
            let params = {
                url: this.urlGenerateOutput,
                obj: {
                    movementBulkId: this.id,
                    items: this.items
                },
                notNotifyToast: true
            };
            this.postApi(params).then((response) => {
                if (response.success) {
                    this.removeLoading(["btnGenerateOutput"]);
                    this.getById();
                    this.getAll();
                    this.loadingGenerate = false;
                    this.success = true;
                }
            });
        },
        edit() {
            this.openSideBar();
            this.removeLoading(["btnEdit"]);

        },
        back() {
            this.success = false;
            this.removeLoading(["btnBack"]);
        }
    },
    watch: {
        event: {
            handler(event) {
                if (event.name == "executedSearchByBarcode")
                    this.addProduct(event.data);

                if (event.name == "productBulkUpdate" || event.name == "updateIncrementDecrement") {
                    this.update(event.data);
                    this.validate(event.data);
                }

                if (event.name == "productBulkRemoved") {
                    this.delete(event.data.id);
                }

                if (event.name == "openError") {
                    this.messagesError = event.data;
                    this.openModal("error");
                }

                if (event.name == "openConflict") {
                    this.openModal("conflict");
                }

                if (event.name == "movementBulkUpdated") {
                    this.getById();
                }
            },
            deep: true,
        },
        search: {
            handler() {
                this.applyFilter();
            },
            deep: true,
        },
        items: {
            handler() {
                this.productRentFiltered = this.items;
            },
            deep: true,
        },
    },
}

</script>

<style scoped>
.div-items {
    margin-top: 20px;
}

.table th,
.table td {
    height: 10px !important;
    padding-left: 5px !important;
    padding-top: 7px !important;
    padding-bottom: 5px !important;
    padding-right: 5px !important;
    padding-left: 10px !important;
    border-bottom: 0px !important;
}

.title-header {
    font-size: 14px;
    color: #757d8c;
    font-weight: 400;
    text-transform: uppercase;
}

.title-main {
    font-weight: 500;
    margin-right: 3px;
    font-size: 15px;
}

input {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    border: 1px solid #E5E4E8;
    border-radius: 20px;
    outline: none;
    font-size: 13px;
    width: 300px;
    margin-bottom: 20px;
}

.icon-output {
    color: #009183;
}

.icon-return {
    color: #B8860B;
}

.icon-refresh {
    margin-left: -10px;
    font-size: 20px;
    cursor: pointer;
}

.refresh-btn:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.loading {
    animation: spin 0.8s linear infinite;
}

.icon-filter-dash {
    font-size: 19px;
}

.div-alert {
    margin-top: 20px;
    margin-bottom: -15px;
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>